import React, {useContext} from "react";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import landingPerson from "../../assets/lottie/landingPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import {illustration, greeting} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function Greeting() {
  const {isDark} = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1
                className={isDark ? "dark-mode greeting-text" : "greeting-text"}
              >
                {" "}
                {greeting.title}{" "}
                <span className="wave-emoji">{emoji("👋")}</span>
              </h1>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                {greeting.subTitle}
              </p>
              <SocialMedia />
              <div className="button-greeting-div">
                {/* <Button text="Contact me" href="#contact" /> */}
                {greeting.resumeLink && (
                  <a
                    href={require("../../assets/images/resume_valrulete.pdf")}
                    download="Resume.pdf"
                    className="download-link-button"
                  >
                    <Button text="Download my resume" />
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
            {illustration.animated ? (
              <>
             <div style={{ 
  border: '2px solid black', 
  borderRadius: '8px', 
  padding: '4px', 
  display: 'inline-block',
  background: 'linear-gradient(to bottom right, #ff8a00, #da1b60)', /* Gradient background */
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)', /* Box shadow for depth */
  }}>
  <img
    src={require("../../assets/images/profile.jpg")}  
    alt="man sitting on table"
    style={{  
      objectFit: 'cover', 
      borderRadius: '4px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.2)' /* Optional inner shadow for the image */
    }}
  />
</div>

              </>


            ) : (
              <img
                alt="man sitting on table"
                src={require("../../assets/images/profile.jpg")}
              ></img>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
}
