import React, {useContext} from "react";
import "./WorkExperience.scss";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import {workExperiences} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

export default function WorkExperience() {
  const cardStyle = {
    width: '300px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    overflow: 'hidden',
    margin: '16px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
  };
  const {isDark} = useContext(StyleContext);
  if (workExperiences.display) {
    return (
      <div id="experience">
        <Fade bottom duration={1000} distance="20px">
          <div className="experience-container" id="workExperience">
            <div>
              <h1 className="experience-heading">PROJECT DONE</h1>
              <div className="experience-cards-div">
                {workExperiences.experience.map((card, i) => {
                  return (

                    <div style={cardStyle}>
                    <img src={card.companylogo} alt="Example" style={{ width: '100%', height: 'auto' }} />
                    <div style={{ padding: '16px'  }}>
                      <h5 style={{ fontSize: '1.25rem', marginBottom: '8px' , textTransform: 'uppercase'}}>{card.title}</h5>
                      <p style={{ color: '#555' }}>{card.desc} </p>
                      {(card.link !='')? <a href={card.link} style={{ display: 'inline-block', padding: '8px 16px', backgroundColor: '#55198b', color: 'white', textDecoration: 'none', borderRadius: '4px', textAlign: 'center' }}>Live</a> : '' }
                      
                    </div>
                  </div>
                   
                  );
                })}
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
  return null;
}
